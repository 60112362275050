import { Box, BoxProps, Image } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';

type AnimationPlayerProps = { images: string[]; frameRate?: number; ratio?: number; delay?: number } & BoxProps;

const AnimationPlayer = (props: AnimationPlayerProps) => {
  const { images, frameRate = 24, ratio = 100, delay = 0, ...rest } = props;

  const [index, setIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    const handleAnimation = () => {
      setIndex((prevIndex) => {
        if (prevIndex === images.length - 1) {
          setIsPaused(true);
          setTimeout(() => {
            setIsPaused(false);
          }, delay);
          return 0;
        }
        return prevIndex + 1;
      });
    };

    if (!isPaused) {
      const interval = setInterval(handleAnimation, 1000 / frameRate);
      return () => clearInterval(interval);
    }
  }, [images.length, frameRate, delay, index, isPaused]);

  return (
    <Box width={'100%'} display={'flex'} flexDir={'column'} alignItems={'center'} justifyContent={'center'} {...rest}>
      <Image width={`${ratio}%`} src={images[index]} />
    </Box>
  );
};
export default AnimationPlayer;
