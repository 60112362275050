import { Box, Flex, Text, Image } from '@chakra-ui/react';
import pc from '../../../assets/image/home/pc.png';
import forg from '../../../assets/image/home/frog.png';
import surf from '../../../assets/image/home/surf.gif';
import Sentiment from '../../../assets/image/home/Sentiment.png';
import Strategy from '../../../assets/image/home/Strategy.png';
import Trading from '../../../assets/image/home/Trading.png';
import { useState } from 'react';
import { importAll } from '../../../utils/utils';
import { useIsMobile } from '../../../hooks/useIsMobile';

const icons = importAll(require.context('../../../assets/image/home/solution', false, /\.(svg)$/));

const items = [
  {
    label: 'TRADING ASSISTANCE',
    value: 0,
    desc: 'Trading Assistance offers personalized suggestions and predictions, based on users&apos; trading behaviors and relevant news, to deliver tailored trading signals and empower traders to make informed decisions more efficiently and intelligently.'
  },
  {
    label: 'SENTIMENT ANALYSIS',
    value: 1,
    desc: 'Sentiment Analysis gauges the overall market sentiment for a particular token, to allow users to make data-driven price predictions. Positive sentiment may indicate a potential price increase, while negative sentiment may suggest a potential price drop.'
  },
  {
    label: 'TRENDING SCORE',
    value: 2,
    desc: 'Strategy Square provides access to AI-optimized trading strategies, along with a community forum for exchanging insight and trading tips. Users are also granted the ability to develop their own AI-powered trading strategies.'
  }
];

const solutions = [
  {
    title: 'Private and Secure',
    content:
      'D3X ensures your transactions and data are fully protected with advanced encryption and AI-driven threat detection. Your privacy is our priority, allowing you to trade with confidence and peace of mind.'
  },
  {
    title: 'Seamless Trading',
    content:
      'Experience smooth trading with D3X&apos;s fast, reliable and user-friendly interface that integrates seamlessly, making trading intuitive and efficient.'
  },
  {
    title: 'Personalized',
    content:
      'D3X delivers tailored, AI-driven trading insights. Our platform analyzes your patterns to provide personalized recommendations, enhancing your trading experience and helping you make informed decisions.'
  },
  {
    title: 'Efficient',
    content:
      'D3X streamlines operations, ensuring rapid trade execution and reduced latency. Act quickly on market opportunities with our efficient system, maximizing your trading potential with minimal effort.'
  },
  {
    title: 'Sentiment analysis',
    content:
      'Stay informed with D3X&apos;s advanced sentiment analysis. The tool monitors market sentiment shifts, providing proactive alerts and comprehensive market insights for better trading decisions to help you stay ahead of trends.'
  },
  {
    title: 'Transaction Diversity',
    content:
      'D3X supports diverse assets and transactions. Our AI-driven analytics guide you in exploring new markets, optimizing your trading strategies, and discovering new opportunities with ease.'
  }
];

const screens = [Sentiment, Strategy, Trading];

export default function () {
  const [current, setCurrent] = useState(0);

  const isMobile = useIsMobile();

  return (
    <Flex width="100%" flexDir="column" color="white" justifyContent={'center'} alignItems={'center'}>
      <Image
        display={isMobile ? 'none' : 'block'}
        data-aos="fade-right"
        data-aos-once={true}
        data-aos-duration="1500"
        src={surf}></Image>
      {/* marginRight={isMobile ? '0px' : '-560px'} */}
      <Text
        textAlign={'center'}
        textTransform="uppercase"
        fontSize={isMobile ? '20px' : '48px'}
        fontWeight={600}
        data-aos="fade-right"
        data-aos-once={true}
        data-aos-duration="1500">
        Smart AI-Trade Assistant in <span style={{ fontFamily: 'XYBER' }}>DEX</span>
      </Text>
      <Text fontSize="20px" data-aos="fade-left" data-aos-once={true} data-aos-duration="1500" textAlign={'center'}>
        D3X is an Al-driven decentralized exchange, pioneering a unified liquidity pool across multiple blockchains.
      </Text>

      <Box marginTop={'80px'}>
        <Flex gap={['0 10px']} alignItems={'center'} flexWrap={'wrap'}>
          {items.map((item, i) => {
            return (
              <Box
                onClick={() => {
                  setCurrent(i);
                }}
                width={isMobile ? '100%' : '300px'}
                borderRadius={'20px'}
                height={'42px'}
                padding={'10px 40px'}
                textAlign={'center'}
                key={item.label}
                cursor={'pointer'}
                bg={current === item.value ? '#060F3F' : ''}
                color={current === item.value ? '#394DEE' : '#fff'}>
                {item.label}
              </Box>
            );
          })}
        </Flex>

        <Box color={'#777E90'} maxWidth={'800px'} textAlign={'center'} fontSize={'14px'} marginTop={'10px'}>
          <div dangerouslySetInnerHTML={{ __html: items[current].desc }}></div>
        </Box>
      </Box>

      <Box marginTop={'36px'} pos={'relative'}>
        <Image src={screens[current]} pos={'absolute'} top={'5%'} left={'4%'} w={'92%'}></Image>
        <Image src={pc}></Image>
      </Box>

      <Text marginTop={'216px'} fontSize={isMobile ? '12px' : '24px'} color={'#394DEE'}>
        WHY D3X?
      </Text>

      <Text
        textAlign={'center'}
        textTransform="uppercase"
        fontSize={isMobile ? '20px' : '48px'}
        fontWeight={600}
        data-aos="fade-right"
        data-aos-once={true}
        data-aos-duration="1500">
        D3X Smart AI Trade Solution <br /> revolutionizing how you interact <br /> with real-time market trading data.
      </Text>
      <Flex gap={['10px']} alignItems={'center'} justifyContent={'space-between'} flexWrap={'wrap'} maxWidth={'1200px'}>
        {solutions.map((item, i) => {
          return (
            <Flex key={item.title} w={isMobile ? '45%' : '28%'} margin={'24px 0'} flexDir={'column'} gap={'10px'}>
              <Image src={icons[i]} margin={isMobile ? '0 auto' : ''} width={'32px'} height={'32px'}></Image>
              <Box fontSize={'24px'} textAlign={isMobile ? 'center' : 'left'}>
                {item.title}
              </Box>
              <Box
                color={'#777E90'}
                fontSize={isMobile ? '14px' : '18px'}
                textAlign={isMobile ? 'center' : 'left'}
                minHeight={'190px'}>
                <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
              </Box>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
}
