import axios from 'axios';

const baseUrl = process.env.REACT_APP_D3X_SERVICE_URL!;

type StatsType = {
  addressCount: number;
  totalVolume: string;
  tradeCount: number;
};
const statsUrl = baseUrl + '/trade/stats';
export const fetchStats = async () => {
  const result = await axios.get<StatsType>(statsUrl);
  return result.data;
};
