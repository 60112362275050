import { Box, Flex } from '@chakra-ui/react';
import ReactECharts from 'echarts-for-react';
import styles from './TokenCard.module.scss';
import classnames from 'classnames';
import { getChartOptions } from './utils';

type Prop = {
  className?: string;
  name: string;
  trend: 'up' | 'down';
  fluctuation: string | number;
  price: string | number;
  imgSrc: string;
  chartData: number[];
  tradeLink: string;
};

export function TokenCard({ className, name, trend, fluctuation, price, imgSrc, chartData, tradeLink }: Prop) {
  const isPriceUp = trend === 'up';
  return (
    <>
      <a
        href={tradeLink.length > 0 ? tradeLink : undefined}
        style={{ cursor: 'pointer' }}
        rel="noreferrer noopener"
        target="_blank">
        <Flex className={classnames(styles.container, className)}>
          <img src={imgSrc} alt={name} className={styles.img} />
          <Box className={styles.name}>{name}</Box>
          <Flex alignItems="center" gap="0.2rem" fontWeight={600}>
            <Box className={classnames(styles.priceTrendImg, isPriceUp ? styles.priceUpImg : styles.priceDownImg)} />
            <Box className={classnames(styles.fluctuation, isPriceUp ? styles.priceUp : styles.priceDown)}>
              {fluctuation}%
            </Box>
          </Flex>
          <Box className={styles.price}>$ {price}</Box>
          <Box className={styles.chart}>
            <Box className={styles.innerChartContainer}>
              <ReactECharts option={getChartOptions({ chartData, trend })} style={{ height: 200, width: '100%' }} />
            </Box>
          </Box>
        </Flex>
      </a>
    </>
  );
}
