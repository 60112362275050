import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { usePrice } from '../../../hooks/usePrice';
import { Carousel } from '../../Trends/Carousel';
import { TokenCardSkeleton } from '../../Trends/TokenCardSkeleton';

const NewHomeTrend = () => {
  const { isSuccess, data: result } = usePrice();
  const handleButtonClick = () => {
    const url = 'https://app.d3x.exchange?theme=dark ';
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Flex width="100%" flexDir="column" gap="30px">
      <Text
        textTransform="uppercase"
        fontFamily="XYBER"
        fontSize={['24px', null, '48px']}
        align={'center'}
        color="white"
        data-aos="fade-down"
        data-aos-once={true}>
        Market Trends
      </Text>
      <Box
        border={'1px solid #394DEE'}
        color={'#fff'}
        width={'148px'}
        height={'48px'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        borderRadius={'24px'}
        margin={'0 auto'}
        cursor={'pointer'}
        onClick={handleButtonClick}>
        Trade now
      </Box>
      {isSuccess ? (
        <Carousel items={result!} />
      ) : (
        <Flex width="100%" alignItems="center" justifyContent="center" gap="30px" overflow={'hidden'}>
          {[...Array(3).keys()].map((item) => {
            return <TokenCardSkeleton key={item} />;
          })}
        </Flex>
      )}
    </Flex>
  );
};

export default NewHomeTrend;
