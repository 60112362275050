import { ethers } from 'ethers';
import { useEffect, useState } from 'react';

export const truncAddress = (addr: string) => {
  if (addr && addr.length === 42 && addr !== ethers.constants.AddressZero) {
    return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
  }
  return '-';
};

export function truncateNumber(input: string | number, maxDecimalDigits = 4) {
  const str = String(input);
  if (str.includes('.')) {
    const parts = str.split('.');
    let fracturePart = parts[1].slice(0, maxDecimalDigits);
    while (fracturePart.endsWith('0') && fracturePart.length > 1) {
      fracturePart = fracturePart.slice(0, -1);
    }
    return parts[0] + '.' + fracturePart;
  }

  return str;
}

export function stringToBytes32(input: string): string {
  return ethers.utils.formatBytes32String(input);
}

export function bytes32ToString(input: string): string {
  return ethers.utils.parseBytes32String(input);
}

export function hexify(input: string): string {
  // return ethers.utils.hexlify(ethers.utils.toUtf8Bytes(input));
  const result = ethers.utils.formatBytes32String(input);
  return result;
}

export async function callWithToastError<T>(func: () => Promise<T>, toast: any): Promise<T | null> {
  try {
    return await func();
  } catch (e: unknown) {
    console.error(e);
    if (e instanceof Error) {
      toast({
        description: e.message,
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    }
    return null;
  }
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export const commonHandleCopy = (toast: any, t: any) => {
  toast({
    description: t('Copied to clipboard', 'Copied to clipboard'),
    status: 'success',
    duration: 2000,
    isClosable: false
  });
};

export const makeErrorToast = (str: string, toast: any) => {
  toast({
    description: str,
    status: 'error',
    duration: 2000,
    isClosable: true
  });
};
export const makeSuccessToast = (str: string, toast: any) => {
  toast({
    description: str,
    status: 'success',
    duration: 2000,
    isClosable: true
  });
};

export const padZero = (value: string | number) => {
  return String(value).padStart(2, '0');
};

export function importAll(r) {
  return r.keys().map((item) => r(item));
}

export const FixRewind = (slider) => {
  slider.on('animationEnded', (slider) => {
    const { rel, progress } = slider.track.details;
    if (rel === 0 && progress !== 0) {
      slider.moveToIdx(0, true, { duration: 0 });
    }
  });
};