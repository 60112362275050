import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  useDisclosure
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import logo from '../../assets/image/logo2.png';
import NavMenu from '../NavMenu/NavMenu';
import Button from '../UI/Button/Button';

const NewHeader = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleButtonClick = () => {
    const url = 'https://app.d3x.exchange?theme=dark ';
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };
  return (
    <>
      <Flex
        zIndex={2}
        position="sticky"
        top={0}
        bg="rgba(0, 0, 0, 0.2)" // Semi-transparent white background
        backdropFilter="blur(10px)" // Blur effect
        width="100%"
        padding={['15px', null, '15px 50px']}
        overflow="hidden"
        alignItems="center"
        justifyContent="space-between"
        color="white">
        {/* section 1 */}
        <Link to="/">
          <Image src={logo} height={['20px', null, '25px']} />
        </Link>
        {/* section 2 */}
        <NavMenu display={['none', null, 'flex']} />
        {/* section 3 */}
        <>
          <Button rounded="full" display={['none', null, 'block']} onClick={handleButtonClick}>
            Launch App
          </Button>
          <HamburgerIcon display={['block', null, 'none']} fontSize={'21px'} onClick={onOpen} />
        </>
      </Flex>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size={'full'}>
        <DrawerOverlay />
        <DrawerContent bgColor={'black'}>
          <DrawerCloseButton color={'white'} />
          <DrawerBody
            display={'flex'}
            flexDir={'column'}
            alignItems={'flex-start'}
            justifyContent={'space-between'}
            paddingY="50px">
            <NavMenu />
            <Button rounded="full">Launch App</Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default NewHeader;
