import React from 'react';
import { CarouselItem } from './CarouselItem';
import { PriceApiResult } from '../../types';
import Slider from 'react-slick';

type Prop = {
  items: Array<PriceApiResult>;
};

const settings = {
  infinite: true,
  autoplaySpeed: 3000,
  speed: 3000,
  autoplay: true,
  arrows: false,
  dots: false,
  variableWidth: true,
  cssEase: 'linear',
  centerMode: true
};

export function Carousel({ items }: Prop) {
  return (
    <Slider {...settings}>
      {items.map((item, idx) => (
        <CarouselItem key={idx} item={item} />
      ))}
    </Slider>
  );
}
