import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { ScrollToTop } from './hooks/useScrollToTop';
import { useLocalStorage } from 'usehooks-ts';
import NewHeader from './components/NewHeader/NewHeader';
import { Box } from '@chakra-ui/react';
import NewFooter from './components/NewFooter/NewFooter';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useIsMobile } from './hooks/useIsMobile';

function App() {
  const location = useLocation();
  const path = location.pathname;
  const notShowFooter = path.includes('/media-kit');
  const navigate = useNavigate();
  const [loggedInTimestamp] = useLocalStorage('__PROSPER_EX__sign_timestamp', 0);

  const isMobile = useIsMobile();

  useEffect(() => {
    AOS.init({ duration: 1000, disable: isMobile });
    AOS.refresh();
  }, []);

  useEffect(() => {
    // 每过一段时间要重新验证
    const timer = setInterval(() => {
      if (loggedInTimestamp && Date.now() - loggedInTimestamp - 10 * 60 * 1000 > 0) {
        navigate('/login');
      }
    }, 60 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, [loggedInTimestamp]);

  return (
    <Box position={'relative'} bgColor={'black'} overflow={'hidden'}>
      <ScrollToTop />
      <NewHeader />
      <Outlet />
      {!notShowFooter && <NewFooter />}
    </Box>
  );
}

export default App;
