import { Flex, Box, Spinner, Text } from '@chakra-ui/react';
import React, { Suspense } from 'react';
import { useIsMobile } from '../../../hooks/useIsMobile';
import Button from '../../UI/Button/Button';
import styles from './NewHomeBanner.module.scss';

// const Spline = React.lazy(() => import('@splinetool/react-spline'));
import Spline from '@splinetool/react-spline';

const NewHomeBanner = () => {
  const isMobile = useIsMobile();

  const handleButtonClick = () => {
    const url = 'https://app.d3x.exchange?theme=dark ';
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  if (isMobile) {
    return (
      <Box width="100%" height="80vh" position={'relative'} id="bannerContainer">
        <video autoPlay muted loop playsInline className={styles.video}>
          <source src="static/videos/home/mobileBanner.mp4" type="video/mp4" />
        </video>
        <Box
          position={'absolute'}
          bottom={'8%'}
          left={'50%'}
          transform={'translate(-50%, 8%)'}
          width={'100%'}
          color={'white'}
          display={'flex'}
          flexDir={'column'}
          gap={'25px'}
          alignItems={'flex-start'}
          padding={'15px'}>
          <Text fontFamily="XYBER" fontSize={'26px'}>
            Trade Decentralized
          </Text>
          <Text fontFamily="XYBER">Unifying Liquidity Network</Text>
          <Button width={'100%'} onClick={handleButtonClick}>
            Trade Now
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Suspense
      fallback={
        <Flex width={'100vw'} height={'100vh'} alignItems={'center'} justifyContent={'center'}>
          <Spinner thickness="4px" speed="0.65s" color="#394DEE" size="xl" />
        </Flex>
      }>
      <Box
        id="bannerContainer"
        width="100%"
        position={'relative'}
        // minHeight={['auto', null, '100vh']}
        display={'flex'}
        flexDir={'column'}
        alignItems={'center'}
        gap={'60px'}>
        <Spline scene="https://prod.spline.design/81QdQ1boQWwkpO1f/scene.splinecode" />
        {/* {buttonVisible && (
          <Button
            size="lg"
            position={['relative', null, 'absolute']}
            top={['none', null, '60%']}
            left={['none', null, '30%']}
            transform={['none', null, 'translate(-50%, -50%)']}>
            Trade Now
          </Button>
        )} */}
      </Box>
    </Suspense>
  );
};

export default NewHomeBanner;
