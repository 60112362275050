import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import './i18n';

import App from './App';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './index.css';
// import EarlyBird from './pages/EarlyBird';
// import { TermsAndConditions } from './pages/TermsAndConditions';
// import Register from './pages/register/Register';
import WalletProvider from './store/wallet-context';
import WagmiProvider from './store/wagmi-context';
// import Login from './pages/login/Login';
// import Profile from './pages/profile';
// import NodeSale from './pages/nodeSale';
import NameserviceProvider from './store/nameservice-context';
// import Group from './pages/group';
import { bsc } from 'wagmi/chains';
// import Treasury from './pages/treasury';
// import Otc from './pages/otc';
import NewHome from './pages/NewHome/NewHome';
import 'keen-slider/keen-slider.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MediaKit from './pages/MediaKit/MediaKit';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      // {
      //   path: '',
      //   element: <Home />
      // },
      {
        path: '',
        element: <NewHome />
      },
      {
        path: 'media-kit',
        element: <MediaKit />
      }
      // {
      //   path: 'earlybird',
      //   element: <EarlyBird />
      // },
      // {
      //   path: 'register',
      //   element: <Register />
      // },
      // {
      //   path: 'treasury',
      //   element: <Treasury />
      // },
      // {
      //   path: 'login',
      //   element: <Login />
      // },
      // {
      //   path: 'profile',
      //   element: <Profile />
      // },
      // {
      //   path: 'group',
      //   element: <Group />
      // },
      // {
      //   path: 'node-sale',
      //   element: <NodeSale />
      // },
      // {
      //   path: 'otc',
      //   element: <Otc />
      // },
      // {
      //   path: 'terms-and-conditions',
      //   element: <TermsAndConditions />
      // }
    ]
  },
  { path: '*', element: <Navigate to={'/'} replace /> }
]);

const chains = [bsc];
root.render(
  <ChakraProvider>
    <QueryClientProvider client={queryClient}>
      <WagmiProvider>
        <NameserviceProvider>
          <WalletProvider>
            <RouterProvider router={router} />
          </WalletProvider>
        </NameserviceProvider>
      </WagmiProvider>
    </QueryClientProvider>
  </ChakraProvider>
);
