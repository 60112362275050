import { ButtonProps, Button as ChakraButton } from '@chakra-ui/react';
import React from 'react';

const Button = (props: ButtonProps) => {
  return (
    <ChakraButton
      userSelect="none"
      bgColor={'#394DEE'}
      fontSize={'14px'}
      color={'white'}
      cursor={'pointer'}
      _hover={{ bgColor: '#394DEE' }}
      _active={{ bgColor: '#394DEE' }}
      {...props}>
      {props.children}
    </ChakraButton>
  );
};

export default Button;
