import React from 'react';
import { Box } from '@chakra-ui/react';
import './Carousel.module.scss';
import { TokenCard } from './TokenCard';
import { PriceApiResult } from '../../types';
type Prop = {
  item: PriceApiResult;
};
export function CarouselItem({ item }: Prop) {
  return (
    <Box>
      <TokenCard {...item} />
    </Box>
  );
}
