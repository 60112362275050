import { Box, Flex, Image, SimpleGrid, Text } from '@chakra-ui/react';
import React from 'react';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useKeenSlider } from 'keen-slider/react';
import { FixRewind } from '../../../utils/utils';
import Slider from 'react-slick';

type NewHomeBrandListProps = {
  title: string;
  brands: {
    name: string;
    image: string;
    link?: string;
    image_m?: string;
  }[];
  disabled?: boolean;
};

const settings = {
  infinite: true,
  autoplaySpeed: 3000,
  speed: 3000,
  autoplay: true,
  arrows: false,
  dots: false,
  variableWidth: true,
  cssEase: 'linear'
};

const NewHomeBrandList = ({ title, brands, disabled = false }: NewHomeBrandListProps) => {
  const isMobile = useIsMobile();

  const [sliderRef] = useKeenSlider(
    {
      loop: true,
      mode: 'free',
      slides: { origin: 'center', perView: 'auto' },
      drag: true,
      created(s) {
        s.next();
      },
      // updated(s) {
      //   s.next();
      // },
      animationEnded(s) {
        s.next();
      },
      defaultAnimation: { duration: 3000, easing: (t: any) => t }
    },
    [FixRewind]
  );

  const handleButtonClick = (url: string) => {
    if (url === undefined || url.length === 0) return;

    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Flex width="100%" flexDir="column" gap="50px">
      <Text color="white" fontSize="21px" opacity={0.7} textAlign={'center'}>
        {title}
      </Text>
      {!isMobile && !disabled && (
        <Box width={'100%'}>
          <Slider {...settings}>
            {brands.map((brand) => {
              return (
                <Box
                  key={brand.name}
                  borderRadius="8px"
                  _hover={{ bgColor: '#1E1F25' }}
                  padding={'15px 30px'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  color={'white'}
                  onClick={() => handleButtonClick(brand.link)}>
                  <Image src={brand.image} height={'30px'} />
                </Box>
              );
            })}
          </Slider>
        </Box>
      )}
      {!isMobile && disabled && (
        <Box width={'100%'} display={'flex'} alignItems={'center'} flexWrap={'wrap'} justifyContent={'center'}>
          {brands.map((brand) => {
            return (
              <Box
                key={brand.name}
                borderRadius="8px"
                _hover={{ bgColor: '#1E1F25' }}
                width={'220px'}
                paddingY={'15px'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                onClick={() => handleButtonClick(brand.link)}>
                <Image src={brand.image} height={'30px'} />
              </Box>
            );
          })}
        </Box>
      )}
      {isMobile && (
        <SimpleGrid columns={2} spacing={1}>
          {brands
            .filter((item) => !!item.image_m)
            .map((brand) => {
              return (
                <Box
                  key={brand.name}
                  borderRadius="8px"
                  padding={'10px'}
                  _hover={{ bgColor: '#1E1F25' }}
                  display="flex"
                  width={'100%'}
                  alignItems="center"
                  justifyContent="center"
                  onClick={() => handleButtonClick(brand.link)}>
                  <Image src={brand.image_m} height={'60px'} />
                </Box>
              );
            })}
        </SimpleGrid>
      )}
    </Flex>
  );
};

export default NewHomeBrandList;
