import { AspectRatio, Box, Flex, Image, Link, Text } from '@chakra-ui/react';
import React from 'react';

import onfiRevolution from '../../../assets/image/home/news/onfiRevolution.png';
import benefitsOfTokenization from '../../../assets/image/home/news/benefitsOfTokenization.png';
import { useKeenSlider } from 'keen-slider/react';
import fish5 from '../../../assets/image/home/fish5.png';
import { useIsMobile } from '../../../hooks/useIsMobile';

const NEWS = [
  {
    image: onfiRevolution,
    title: 'The OnFi Revolution',
    content: 'OnFi, or On-Chain Finance, is the Web3 vertical most likely to attract institutional attention',
    link: 'https://d3xxyz.medium.com/the-onfi-revolution-bridging-the-gap-between-traditional-finance-and-blockchain-d36947a808c1'
  },
  {
    image: benefitsOfTokenization,
    title: 'The 5 Key Benefits of Tokenization in 2024',
    content:
      'Why 2024 will be the year of tokenization — What’s the Big Deal? Tokenization promises to be not only a buzzword, but the key concept driving the crypto world in 2024.',
    link: 'https://medium.com/prosperex-official/the-5-key-benefits-of-tokenization-in-2024-fcb71ee93400'
  }
];

const NewHomeNews = () => {
  const [sliderRef] = useKeenSlider({
    loop: false,
    mode: 'snap',
    slides: { origin: 'auto', perView: 'auto', spacing: 10 },
    drag: true
  });

  const isMobile = useIsMobile();

  return (
    <Box
      width={'100%'}
      display={'flex'}
      flexDir={'column'}
      alignItems={'center'}
      color={'white'}
      gap={'30px'}
      position={'relative'}>
      {/* <Text fontSize={['24px', null, '48px']} data-aos="fade-down" data-aos-once={true}>
        NEWEST
      </Text> */}

      {/* data-aos="fade-down"
        data-aos-once={true}
        data-aos-duration="1500" */}
      <Text textAlign={'center'} textTransform="uppercase" fontSize={isMobile ? '20px' : '48px'} fontWeight={600}>
        NEWEST
      </Text>

      <Box ref={sliderRef} className="keen-slider" width={'100%'}>
        {NEWS.map((item) => (
          <Link
            key={item.title}
            href={item.link}
            isExternal
            _hover={{ textDecoration: 'none' }}
            minWidth={['100%', null, '480px']}
            maxWidth={['100%', null, '480px']}>
            <Flex
              flexDir={['column', null, 'row']}
              width={'100%'}
              alignItems={'flex-start'}
              borderRadius={'8px'}
              _hover={{ bgColor: '#1E1F25' }}
              padding={['25px', null, '15px']}
              gap={'25px'}
              className="keen-slider__slide">
              <AspectRatio width={'100%'} flex={['none', null, 1]} overflow={'hidden'} borderRadius={'20px'} ratio={1}>
                <Image src={item.image} objectFit={'cover'} width={'100%'} height={'100%'} />
              </AspectRatio>
              <Flex width={'100%'} flex={['none', null, 1]} fontSize={'14px'} gap={'10px'} flexDir={'column'}>
                <Text>{item.title}</Text>
                <Text
                  opacity={'0.5'}
                  maxH={'120px'}
                  overflowY={'scroll'}
                  sx={{
                    '::-webkit-scrollbar': { display: 'none' },
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none'
                  }}>
                  {item.content}
                </Text>
              </Flex>
            </Flex>
          </Link>
        ))}
      </Box>
      <Image src={fish5} position={'absolute'} right={'0px'} top={'-100px'}></Image>
    </Box>
  );
};

export default NewHomeNews;
