import React, { useEffect } from 'react';
import { Box, Container, Image } from '@chakra-ui/react';
import { Fade } from 'react-awesome-reveal';
import NewHomeBanner from '../../components/NewHome/NewHomeBanner/NewHomeBanner';
import NewHomeStats from '../../components/NewHome/NewHomeStats/NewHomeStats';
import NewHomeTrend from '../../components/NewHome/NewHomeTrend/NewHomeTrend';
import NewHomeFlowchart from '../../components/NewHome/NewHomeFlowchart/NewHomeFlowchart';
import NewHomeBrandList from '../../components/NewHome/NewHomeBrandList/NewHomeBrandList';
import emurgo from '../../assets/image/home/investors/emurgo.png';
import centralResearch from '../../assets/image/home/investors/centralResearch.png';
import acCapital from '../../assets/image/home/investors/acCapital.png';
import roaming from '../../assets/image/home/investors/roaming.png';
import magnus from '../../assets/image/home/investors/magnus.png';

import emurgo_m from '../../assets/image/home/investors/emurgo_m.png';
import centralResearch_m from '../../assets/image/home/investors/centralResearch_m.png';
import acCapital_m from '../../assets/image/home/investors/acCapital_m.png';
import roaming_m from '../../assets/image/home/investors/roaming_m.png';
import magnus_m from '../../assets/image/home/investors/magnus_m.png';

import xlayer from '../../assets/image/home/partners/xlayer.png';
import polygon from '../../assets/image/home/partners/polygon.png';
import binance from '../../assets/image/home/partners/binance.png';
import nibiru from '../../assets/image/home/partners/nibiru.png';
import artela from '../../assets/image/home/partners/artela.png';
import conflux from '../../assets/image/home/partners/conflux.png';
import mxc from '../../assets/image/home/partners/mxc.png';
import sui from '../../assets/image/home/partners/sui.png';
import scroll from '../../assets/image/home/partners/scroll.png';
import eraai from '../../assets/image/home/partners/Era.ai.png';

import xlayer_m from '../../assets/image/home/partners/OKX1Layer_m.png';
import polygon_m from '../../assets/image/home/partners/Polygon_m.png';
import binance_m from '../../assets/image/home/partners/BSCchain_m.png';
import nibiru_m from '../../assets/image/home/partners/NIBIRU_m.png';
import artela_m from '../../assets/image/home/partners/ARTELA_m.png';
import conflux_m from '../../assets/image/home/partners/CONFLUX_m.png';
import mxc_m from '../../assets/image/home/partners/MXC_m.png';
import sui_m from '../../assets/image/home/partners/SUI_m.png';
import scroll_m from '../../assets/image/home/partners/SCROLL_m.png';
import eraai_m from '../../assets/image/home/partners/Era.ai_m.png';

import NewHomeNews from '../../components/NewHome/NewHomeNews/NewHomeNews';
import AnimationPlayer from '../../components/AnimatedPlayer/AnimatedPlayer';
import { importAll } from '../../utils/utils';

import bubble1 from '../../assets/image/home/paopao_R.png';
import bubble2 from '../../assets/image/home/new/fish1.png';
import jellyfish from '../../assets/image/home//jellyfish.png';
import jellyfishGif from '../../assets/image/home/new-flow/jellyfish.gif';
import fish from '../../assets/image/home/fish.png';
import NewHomeAssistant from '../../components/NewHome/NewHomeAssistant/NewHomeAssistant';
import seabed from '../..//assets/image/home/seabed.png';

import { useIsMobile } from '../../hooks/useIsMobile';
// import V3x from '../../components/V3x/V3x';

const SHARK_IMAGES = importAll(require.context('../../assets/image/home/shark', false, /\.(png)$/));

const BUBBLE_IMAGES = importAll(require.context('../../assets/image/home/bubbles', false, /\.(png)$/));

const INVESTORS = [
  { name: 'emurgo', image: emurgo, image_m: emurgo_m },
  { name: 'centralResearch', image: centralResearch, image_m: centralResearch_m },
  { name: 'acCapital', image: acCapital, image_m: acCapital_m },
  { name: 'roaming', image: roaming, image_m: roaming_m },
  { name: 'magnus', image: magnus, image_m: magnus_m }
];

const PARTNERS = [
  { name: 'xlayer', image: xlayer, image_m: xlayer_m, link: 'https://www.okx.com/zh-hans/xlayer' },
  { name: 'polygon', image: polygon, image_m: polygon_m, link: 'https://polygon.technology/' },
  { name: 'binance', image: binance, image_m: binance_m, link: 'https://www.bnbchain.org/en/bnb-smart-chain' },
  { name: 'nibiru', image: nibiru, image_m: nibiru_m, link: 'https://nibiru.fi/' },
  { name: 'artela', image: artela, image_m: artela_m, link: 'https://artela.network/' },
  { name: 'conflux', image: conflux, image_m: conflux_m, link: 'https://confluxnetwork.org/' },
  { name: 'mxc', image: mxc, image_m: mxc_m, link: 'https://www.moonchain.com/' },
  { name: 'sui', image: sui, image_m: sui_m, link: 'https://sui.io/' },
  { name: 'scroll', image: scroll, image_m: scroll_m, link: 'https://scroll.io/' },
  { name: 'era', image: eraai, image_m: eraai_m, link: 'https://www.eraai.pro/' },
  { name: 'xlayer_rep1', image: xlayer, link: 'https://www.okx.com/zh-hans/xlayer' },
  { name: 'polygon_rep1', image: polygon, link: 'https://polygon.technology/' },
  { name: 'binance_rep1', image: binance, link: 'https://www.bnbchain.org/en/bnb-smart-chain' },
  { name: 'nibiru_rep1', image: nibiru, link: 'https://nibiru.fi/' },
  { name: 'artela_rep1', image: artela, link: 'https://artela.network/' },
  { name: 'conflux_rep1', image: conflux, link: 'https://confluxnetwork.org/' },
  { name: 'mxc_rep1', image: mxc, link: 'https://www.moonchain.com/' },
  { name: 'sui_rep1', image: sui, link: 'https://sui.io/' },
  { name: 'scroll_rep1', image: scroll, link: 'https://scroll.io/' },
  { name: 'era_rep1', image: eraai, link: 'https://www.eraai.pro/' }
];
// backgroundPosition={'top calc(100vh - 0px) left 0'}
const NewHome = () => {
  // const [marginTop, setMarginTop] = React.useState(0);

  // const onResize = () => {
  //   try {
  //     const el = document.querySelector('#bannerContainer') as HTMLElement;

  //     const canvasEl = document.querySelector('#bannerContainer canvas') as HTMLElement;

  //     console.log(el.offsetHeight - canvasEl.offsetHeight, canvasEl.offsetHeight);

  //     setMarginTop((el.offsetHeight - canvasEl.offsetHeight || 300) - 100);
  //   } catch (err) {
  //     return;
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('resize', onResize);

  //   return () => {
  //     window.removeEventListener('onResize', onResize);
  //   };
  // }, []);

  const isMobile = useIsMobile();

  return (
    <Box width="100%">
      <NewHomeBanner />

      <Box
        width={'100%'}
        position={'relative'}
        zIndex={0}
        backgroundImage={'/static/images/xyz_bg.png'}
        bgSize={'100% 100%'}
        style={{
          marginTop: `-${isMobile ? 40 : 100}px`
        }}>
        <Container
          width={'95%'}
          maxW="2560px"
          display="flex"
          flexDir="column"
          alignItems="center"
          gap={['120px', null, '240px']}
          paddingX={0}
          paddingY={['120px', null, '240px']}
          paddingTop={'30px'}>
          {/* <V3x /> */}
          <Fade style={{ width: '100%' }} triggerOnce delay={500}>
            <NewHomeStats />
          </Fade>
          <NewHomeTrend />
          {/* <NewHomeFlowchart /> */}
          <NewHomeAssistant />
          {/* <NewHomeBrandList title="Investors" brands={INVESTORS} disabled /> */}
          <NewHomeBrandList title="Partners" brands={PARTNERS} />
          <NewHomeNews />
        </Container>

        {/* background layer */}
        <Box
          position={'absolute'}
          width={'100%'}
          height={'100%'}
          display={'flex'}
          flexDir={'column'}
          alignItems={'center'}
          justifyContent={'space-between'}
          top={0}
          left={0}
          zIndex={-1}
          gap={['none', null, '60px']}>
          <Image
            src={bubble1}
            alignSelf={'flex-end'}
            minWidth={'150px'}
            width={'10%'}
            marginRight={['25px', null, '50px']}
            marginTop={['25px', null, '50px']}
          />
          <Image src={bubble2} alignSelf={'center'} minWidth={'200px'} width={'20%'} marginTop={'-520px'} />
          <AnimationPlayer marginTop={'-350px'} images={SHARK_IMAGES} delay={3000} />
          <Image
            src={jellyfish}
            alignSelf={'flex-end'}
            minWidth={'100px'}
            width={'10%'}
            marginRight={['25px', null, '50px']}
          />
          <Image
            src={fish}
            alignSelf={'flex-start'}
            minWidth={'200px'}
            width={'20%'}
            marginLeft={['25px', null, '50px']}
          />
          <Container width={'95%'} maxWidth={'400px'}>
            <Image src={jellyfishGif} minWidth={'100%'} marginRight={'auto'} />
            {/* <video autoPlay muted loop playsInline width={'100%'}>
              <source src="static/videos/home/jellyfish.mp4" type="video/mp4" />
            </video> */}
          </Container>
          <AnimationPlayer images={BUBBLE_IMAGES} />

          <Image src={seabed} minWidth={'100%'} marginBottom={'-520px'} />
        </Box>
      </Box>
    </Box>
  );
};

export default NewHome;
