import React from 'react';
import { Box, Flex, Icon, Image } from '@chakra-ui/react';
import styles from './MediaKit.module.scss';
import Button from '../../components/UI/Button/Button';
import { AiOutlineDownload } from 'react-icons/ai';
import logoDark from '../../assets/image/media-kit/logo-dark.svg';
import logoLight from '../../assets/image/media-kit/logo-light.svg';
import logoExchangeDark from '../../assets/image/media-kit/logo-exchange-dark.svg';
import logoExchangeLight from '../../assets/image/media-kit/logo-exchange-light.svg';
import { useIsMobile } from '../../hooks/useIsMobile';

function Card({ src, name }: { src: string; name: `${string}.SVG` }) {
  const download = () => {
    const filename = name.split('.')[0] + '.svg'; // 文件名
    console.log('filename', filename);
    fetch(src)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch((error) => {
        console.error('Error downloading image:', error);
      });
  };

  return (
    <div className={styles.card}>
      <Image src={src} />
      <div>{name}</div>
      <Button
        variant="outline"
        background="transparent"
        borderColor="#0C33BC"
        className={styles.button}
        onClick={download}>
        <Box lineHeight="24px" fontWeight={400}>
          Download
        </Box>
        <Icon as={AiOutlineDownload} ml="10px" fontSize="14px" />
      </Button>
    </div>
  );
}

const MediaKit = () => {
  const isMobile = useIsMobile();

  const download = () => {
    const filename = 'logo.zip'; // 文件名
    console.log('filename', filename);
    fetch(require('../../assets/image/media-kit/logo.zip'))
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch((error) => {
        console.error('Error downloading zip:', error);
      });
  };

  return (
    <Box className={styles.content} padding={isMobile ? '12px' : ''}>
      <Box alignSelf="flex-start">
        <Box color="white" fontSize="48px" lineHeight="48px" fontWeight="600" mb="20px">
          MEDIA INQUIRIES
        </Box>
        <Button className={styles.button} onClick={download}>
          Download Complete kit
        </Button>
      </Box>
      <Box className={styles.title} mt="40px">
        DEX NETWORK
      </Box>
      <Flex gap="16px" flexWrap="wrap">
        <Card src={logoLight} name="Logo_Light_full.SVG" />
        <Card src={logoDark} name="Logo_Dark_full.SVG" />
      </Flex>
      <Box className={styles.title} mt="32px">
        DEX EXCHANGE
      </Box>
      <Flex gap="16px" flexWrap="wrap">
        <Card src={logoExchangeLight} name="Logo_Exchange_Light_full.SVG" />
        <Card src={logoExchangeDark} name="Logo_Exchange_Dark_full.SVG" />
      </Flex>
    </Box>
  );
};

export default MediaKit;
